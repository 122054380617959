

import React, { useRef, useEffect, useState, forwardRef } from "react";
import { PageHeader, Breadcrumb, Row, Col, Input, DatePicker, Space, Button, Tooltip, Table } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { axios } from "../../../../../App";
import qs from "qs";
import { ReportDataTable } from "../../../components";
import { Pie } from "@ant-design/plots";
import ReactToPrint from "react-to-print";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = (props) => {
  const navigate = useNavigate();
  const componentRef = useRef();

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const [projectSubcontractors, setProjectSubContractors] = useState([]);


  const fetchProjects = async () => {
    const queryObject = {
      populate: "*",
    };

    try {
      const response = await axios.get(`projects?${qs.stringify(queryObject)}`);

      if (response?.data?.data) {
        // Extract all subcontractors along with project names
        const all_subs_with_project_names = response.data.data.flatMap(project =>
          (project.attributes?.sub_contractors?.data || []).map(subcontractor => ({
            project_name: project.attributes.name,
            sub_contractor_name: subcontractor.attributes.name,
            group_name: subcontractor.attributes || 'N/A'
          }))
        );

        // Logging the subcontractors with project names for verification
        console.log('All subcontractors with project names:', all_subs_with_project_names);

        // Set the state with the extracted subcontractors and project names
        setProjectSubContractors(all_subs_with_project_names);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          name: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          model: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          condition: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          createdAt: {
            $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
            $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const reportPdf = (letterheadFileName) => {

    return (
      <>
        <div style={{ position: 'absolute', left: '-9999px', top: 0 }}>
          <MyComponentToPrint
            config={{
              ...config,
              columns: config?.columns?.map((column) => ({
                ...column,
                ...getColumnProps(column),
              })),
            }}
            data={data}
            projectSubcontractors={projectSubcontractors}
            ref={componentRef}
            letterheadFileName={letterheadFileName}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
        </div>
      </>
    )
  }

  useEffect(() => {
    fetchData(filters);
    fetchProjects();

  }, []);

  const projects_data = projectSubcontractors?.map((s) => (
    {
      project_name: s.project_name,
      sub_contractor_name: s.sub_contractor_name,
      group_name: s.group_name,
    }
  ))

  const project_column = [
    {
      title: 'Project Name',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: 'Sub Contractor Name',
      dataIndex: 'sub_contractor_name',
      key: 'sub_contractor_name',
    },
    {
      title: 'Sub Contractor Group',
      dataIndex: 'group_name',
      key: 'group_name',
    },
  ]

  console.log('fcgsdfg', projectSubcontractors)

  return (
    <>
      <ReportDataTable
        data={data}
        projects_data={projects_data}
        projects_column={project_column}
        config={{
          ...config,
          project_title: 'Sub contractor appointed for projects',
          pie_title: 'Sub contractor and Sub contractor groups',
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        pdfContent={reportPdf}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>,
          // <Tooltip placement="top" title="Download Report">
          //   <Button icon={<DownloadOutlined />} onClick={downloadReport} />
          // </Tooltip>,
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

      <div id="pieChart">
        <MyComponentToPrint data={data} />
      </div>

      <div style={{ fontSize: '20px' }}>
        <p style={{ paddingBottom: '20px' }}>Sub contractors appointed for projects</p>
        <Table
          dataSource={projects_data}
          columns={project_column}
          pagination={false}
        // style={{ pageBreakInside: 'avoid' }}
        />
      </div>

      {/* {reportPdf()} */}

    </>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
};

const MyComponentToPrint = forwardRef((props, ref) => {

  const processData = (data) => {
    const groupCounts = data.reduce((acc, item) => {
      const groupName = item.sub_contractor_group.data.attributes.name;
      if (!acc[groupName]) {
        acc[groupName] = 0;
      }
      acc[groupName]++;
      return acc;
    }, {});

    return Object.keys(groupCounts).map(key => ({
      type: key,
      value: groupCounts[key]
    }));
  };

  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    setProcessedData(processData(props?.data));
  }, [props?.data]);

  const pieConfig = {
    appendPadding: 10,
    data: processedData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
    color: ['#CF6C58', '#2E943E', '#2E4494', '#000000', '#4BC0C0'], // Custom colors
  };


  return (

    <div style={{ margin: '5rem 0', fontSize: '20px' }}>
      {/* <p>Sub contractor and Sub contractor groups</p> */}
      <div style={{ border: '1px solid #E9E9E9', width: '60%', height: 'auto', borderRadius: '10px', margin: '20px 0', padding: '10px', }}>
        <Pie {...pieConfig} />
      </div>
    </div>

  );
});

export default Page;
