import React, { useState, useEffect } from "react";
import { DataTable, ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Select } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Bar, Column } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`projects`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Projects",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setProjects(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            date: moment(item?.attributes?.createdAt).format("DD-MM-YYYY"),
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    let filters = [
      {
        $or: [
          {
            heading: {
              $containsi: searchValues?.keyword?.toLowerCase(),
            },
          },
          {
            remarks: {
              $containsi: searchValues?.keyword?.toLowerCase(),
            },
          },
        ],
      },
      // getFiltersForRoles(),
    ];

    // Conditionally add date filter if start_date exists
    if (searchValues?.start_date) {
      filters[0].$or.push({
        createdAt: {
          $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          $lte: searchValues?.end_date
            ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
            : undefined, // If end_date exists, format it, otherwise it's ignored
        },
      });
    }

    if (selectedProject !== "") {
      filters.push({
        project: selectedProject,
      });
    }

    fetchData({
      $and: filters,
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
    fetchProjects()
  }, []);

  const processData = (data) => {
    const result = [];
    data?.forEach((item) => {
      result.push({
        material: item.task_material,
        type: 'Required Quantity',
        value: item.required_quantity,
      });
      result.push({
        material: item.task_material,
        type: 'Released Quantity',
        value: item.released_quantity,
      });
    });
    return result;
  };

  const BarChart = () => {
    const chartData = processData(data);

    const config = {
      data: chartData,
      isStack: true,
      xField: 'material',
      yField: 'value',
      seriesField: 'type',
      color: ['#FF6F61', '#40A9FF'], // Customize colors as needed
      legend: {
        position: 'top-left',
      },
      label: {
        position: 'middle',
        layout: [{ type: 'interval-adjust-position' }, { type: 'interval-hide-overlap' }, { type: 'adjust-color' }],
      },
    };

    return <Column {...config} />;
  };

  console.log('release Form', data)

  return (
    <>
      <ReportDataTable
        data={data}
        config={{
          ...config,
          project_title: '',
          pie_title: '',
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <Select
                  value={selectedProject}
                  onChange={setSelectedProject}
                  options={projects}
                  style={{ width: "100%", marginTop: '20px' }}
                  placeholder="Select Project"
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>,
          // <Tooltip placement="top" title="Download Report">
          //   <Button icon={<DownloadOutlined />} onClick={downloadReport} />
          // </Tooltip>,
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

      <div id="pieChart">
        <div style={{ margin: '5rem 0', fontSize: '20px' }}>
          <p> Count of each type of Material used in different projects</p>
          <div style={{ border: '1px solid #E9E9E9', width: '70%', borderRadius: '10px', margin: '20px 0', padding: '10px' }}>
            <BarChart />
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default Page;
