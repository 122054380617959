

import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Tooltip, Button, Space, Col, DatePicker, Input, Row, Select } from "antd";
import config from "./config";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { axios } from "../../../../../App";
import { ReportDataTable } from "../../../components";
import { Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";


const Page = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [chartData, setchartData] = useState([])

  const onBack = () => navigate(`/quick-projects/reports`);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`projects`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Projects",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setProjects(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to calculate material consumption for each project
  const calculateMaterialConsumption = (data) => {
    const consumption = {};

    data.forEach(item => {
      const project_name = item?.project?.data?.attributes?.name;
      const material_name = item?.material_item?.data?.attributes?.name;
      const released_quantity = item?.released_quantity;

      if (!consumption[material_name]) {
        consumption[material_name] = {};
      }

      if (!consumption[material_name][project_name]) {
        consumption[material_name][project_name] = 0;
      }

      consumption[material_name][project_name] += released_quantity;
    });

    return consumption;
  };

  const transformDataForPieChart = (consumption) => {
    const chartsData = [];

    Object.keys(consumption).forEach(material_name => {
      const materialData = [];

      Object.keys(consumption[material_name]).forEach(project_name => {
        materialData.push({
          project: project_name,
          quantity: consumption[material_name][project_name],
        });
      });

      chartsData.push({
        material_name,
        data: materialData
      });
    });

    return chartsData;
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        // Step 2: Extract release note IDs from the response data
        const releaseNoteIds = response.data.data.map((data) => data.id);

        // Step 3: Create releaseObject with extracted IDs
        const releaseObject = {
          populate: "*",
          "released-notes": releaseNoteIds,
          pagination: {
            limit: MAX_PAGINATION_LIMIT,
          },
        };

        // Step 4: Make API call to get release note data
        const releaseResponse = await axios.get(
          `released-notes?${qs.stringify(releaseObject)}`
        );

        // Step 5: Process and combine data with release form histories
        const combinedDataPromises = response.data.data.map(async (item) => {
          const releaseNote = releaseResponse?.data?.data?.find(
            (note) => note?.attributes?.stock_ledger === item.id
          );

          let releaseFormHistories = null;
          if (releaseNote) {
            // Make additional API call to get release form histories
            const releaseFormHistoriesResponse = await axios.get(
              `released-form-histories?${qs.stringify({
                filters: { released_note: releaseNote.id },
              })}`
            );
            releaseFormHistories = releaseFormHistoriesResponse?.data?.data || null;
          }

          return {
            id: item.id,
            ...item.attributes,
            releaseNote: releaseNote ? releaseNote.attributes : null,
            releaseFormHistories,
            released_quantity: releaseNote ? releaseNote.attributes.released_quantity : null,
            released_date: releaseFormHistories?.[0]?.attributes?.createdAt
          };
        });

        // Step 6: Wait for all promises to resolve
        const combinedData = await Promise.all(combinedDataPromises);

        // Step 7: Update state with the combined data
        setData(combinedData);

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });

        // Calculate total quantity and released_quantity
        let totalQuantity = 0;
        let totalReleasedQuantity = 0;

        combinedData.forEach(item => {
          totalQuantity += item.quantity || 0;
          totalReleasedQuantity += item.released_quantity || 0;
        });

        setchartData([
          { type: 'Total Quantity', value: totalQuantity },
          { type: 'Released Quantity', value: totalReleasedQuantity }
        ]);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    let filters = [
      {
        $or: [
          {
            material_item: {
              name: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
          },
        ],
      },
      getFiltersForRoles(),
    ];

    // Conditionally add date filter if start_date exists
    if (searchValues?.start_date) {
      filters[0].$or.push({
        createdAt: {
          $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          $lte: searchValues?.end_date
            ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
            : undefined, // If end_date exists, format it, otherwise it's ignored
        },
      });
    }

    if (selectedProject !== "") {
      filters.push({
        project: selectedProject,
      });
    }

    setSearched(true);

    fetchData({
      $and: filters,
    });
  };


  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    return buttons;
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      [pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());
    fetchProjects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const QuantityPieChart = () => {
    const config = {
      appendPadding: 10,
      data: chartData,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      label: {
        type: 'inner',
        offset: '-30%',
        content: '{value}',
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [{ type: 'element-active' }],
    };

    return <Pie {...config} />;
  };

  const PieChart = () => {
    const consumption = calculateMaterialConsumption(data);
    const chartsData = transformDataForPieChart(consumption);


    return (
      <div>
        {chartsData.map((chart, index) => {
          const config = {
            appendPadding: 10,
            data: chart.data,
            angleField: 'quantity',
            colorField: 'project',
            radius: 1,
            label: {
              type: 'inner',
              offset: '-30%',
              content: '{value}',
              style: {
                fontSize: 20,
                textAlign: 'center',
              },
            },
            legend: {
              itemName: {
                style: {
                  fontSize: 18, // Increase the font size of the legend items
                },
              },
            },
            interactions: [{ type: 'element-active' }],
          };

          return (
            <div key={index} style={{ margin: '5rem 0', width: '60%' }}>
              <h2 style={{ fontSize: '20px', marginBottom: '20px' }}>{`Total ${chart.material_name} Consumption`}</h2>
              <Pie {...config} />
            </div>
          );
        })}
      </div>
    );
  };

  console.log('ledger report', searchValues)

  return (
    <Box>

      <ReportDataTable
        data={data}
        config={{
          ...config,
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={12}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={6}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={6}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <Select
                  value={selectedProject}
                  onChange={setSelectedProject}
                  options={projects}
                  style={{ width: "100%", marginTop: '20px' }}
                  placeholder="Select Project"
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={renderHeaderButtons()}
        onBack={() => navigate(`/quick-projects/reports`)}
      />

      <div id="barGraph">
        <PieChart />
      </div>
    </Box>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
};

export default Page;