import config from "../config";
import moment from "moment";
import { Tag } from "antd";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "MEETINGS",
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Meeting Type",
      dataIndex: "meeting_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Time",
      dataIndex: "started_at",
      selectable: true,
      sorter: (a, b) =>
        moment(a.started_at).unix() - moment(b.started_at).unix(),
      render: (text) => (
        <span>{moment(text).format("DD-MM-YYYY, HH:mm:ss")}</span>
      ),
      width: 250,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Host",
      dataIndex: "host",
      selectable: true,
      references: {
        fieldName: "first_name",
      },
    },
    {
      title: "Notes Prepared By",
      dataIndex: "notes_prepared_by",
      selectable: true,
      references: {
        fieldName: "first_name",
      },
    },
    // {
    //   title: "Tasks",
    //   dataIndex: "tasks",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Agenda",
      dataIndex: "agenda",
      selectable: true,
    },
    {
      title: "Requirements",
      dataIndex: "requirements",
      selectable: true,
    },
    {
      title: "Target",
      dataIndex: "target",
      selectable: true,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   selectable: true,
    //   render: (text) => <Tag>{text}</Tag>,
    // },
  ],
  ...config,
};
