export const CHART_SLUGS = {
  MEETINGS: "meetings",
  EVENTS: "events",
  REMINDERS: "reminders",
  TO_DOS: "to-dos",
  MAIL_STATUSES: "mail-status",
  CHAT_STATUSES: "chat-status",
};

export const REPORTS = [
  {
    id: "meetings-report",
    name: "Meeting Report",
  },
  {
    id: "meeting-notes-report",
    name: "Meeting Notes Report",
  },
  {
    id: "payrolls-report",
    name: "Payrolls Report",
  },
];
