import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  PageHeader,
  Breadcrumb,
  Tooltip,
  Drawer,
  Checkbox,
  Select,
  Spin,
} from "antd";
import { Helmet } from "react-helmet";
import {
  CaretRightOutlined,
  ReloadOutlined,
  CaretLeftOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import range from "lodash-es/range";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import moment from "moment";
import "./styles.css";
import { useSelector } from "react-redux";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";
const CheckboxGroup = Checkbox.Group;
const todayObj = dayjs();

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
// const plainOptions = [
//   // "Manpower Logs",
//   // "Projects",
//   // "RFIs",
//   // "Task Logs",
//   // "Tasks",
//   // "Consumables",
//   // "Machine Logs",
//   // "Received Notes",
//   // "Advanced Loans",
//   // "Attendances",
//   // "Petty Cashes",
//   // "Reimburses",
//   // "Revised Shifts",
//   // "Rosters",
//   // "Visitors",
// ];

const OPTIONS = [
  {
    id: "tasks",
    label: "Tasks",
  },
  {
    id: "milestones",
    label: "Milestones",
  },
  {
    id: "events",
    label: "Events",
  },
  {
    id: "meetings",
    label: "Meetings",
  },
  {
    id: "reminders",
    label: "Reminders",
  },
  {
    id: "to-dos",
    label: "To Dos",
  },
  {
    id: "holidays",
    label: "Holidays",
  },
  // {
  //   id: "agency-payment-schedules",
  //   label: "Agency Payment Schedules",
  // },
  // {
  //   id: "agency-work-orders",
  //   label: "Agency Work Order",
  // },
  // {
  //   id: "agency-requirement-forms",
  //   label: "Agency Requirement Forms",
  // },
  // {
  //   id: "sub-contractor-payment-schedules",
  //   label: "Sub-contractor Payment Schedules",
  // },
  // {
  //   id: "sub-contractor-work-orders",
  //   label: "Sub-contractor Work Order",
  // },
  // {
  //   id: "sub-contractor-requirement-forms",
  //   label: "Sub-contractor Requirement Forms",
  // },
  {
    id: "vendor-material-requisitions",
    label: "Vendor Material Requisitions",
  },
  {
    id: "vendor-payment-schedules",
    label: "Vendor Payment Schedules",
  },
  {
    id: "vendor-purchase-orders",
    label: "Vendor Purchase Orders",
  },
];
const { Option } = Select;

export const Calendar = () => {
  const [dayObj, setDayObj] = useState(dayjs());
  const [showSettings, setShowSettings] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeId, setEmployeeId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state?.auth);

  const thisYear = dayObj.year();
  const thisMonth = dayObj.month(); // (January as 0, December as 11)
  const daysInMonth = dayObj.daysInMonth();

  const dayObjOf1 = dayjs(`${thisYear}-${thisMonth + 1}-1`);
  const weekDayOf1 = dayObjOf1.day(); // (Sunday as 0, Saturday as 6)

  const dayObjOfLast = dayjs(`${thisYear}-${thisMonth + 1}-${daysInMonth}`);
  const weekDayOfLast = dayObjOfLast.day();

  const handlePrev = () => {
    setDayObj(dayObj.subtract(1, "month"));
  };

  const handleNext = () => {
    setDayObj(dayObj.add(1, "month"));
  };

  const toggleSettings = () => {
    setShowSettings((prev) => !prev);
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < OPTIONS.length);
    setCheckAll(list.length === OPTIONS.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? OPTIONS?.map((item) => item?.label) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const initCheckedList = () => {
    setCheckedList(OPTIONS?.map((item) => item?.label));
  };

  const fetchCalendar = () => {
    setLoading(true);

    fetchData({
      slug: "calendar",
      query: {
        eventTypes: checkedList?.map((item) => {
          return OPTIONS?.find((option) => option?.label === item)
            ?.id?.toLowerCase()
            ?.replace(/ /g, "-");
        }),
        employeeId,
      },
      onSuccess: (data) => {
        setLoading(false);
        setData(data);
      },
    });

    // fetchData({
    //   slug: "created-invoices",
    //   query: {
    //     populate: '*'
    //   },
    //   transformer: (item) => ({
    //     id: item?.id,
    //     name: `${item?.attributes?.approved_by?.data?.attributes?.first_name}`,
    //     type: 'Created Invoice'
    //   }),
    //   onSuccess: (data) => {
    //     setData((prev)=> ([...prev, ...data]));
    //   },
    // });

    // fetchData({
    //   slug: "received-invoices",
    //   query: {
    //     populate: '*'
    //   },
    //   transformer: (item) => ({
    //     id: item?.id,
    //     name: `${item?.attributes?.employee?.data?.attributes?.first_name}`,
    //     type: 'Recieved Invoice'
    //   }),
    //   onSuccess: (data) => {
    //     setData((prev)=> ([...prev, ...data]));
    //   },
    // });
  };

  const fetchItems = async () => {
    fetchCalendar();

    fetchData({
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        uid: item?.uid,
      }),
      onSuccess: (data) => setEmployees(data),
    });
  };

  const onReset = () => {
    setEmployeeId(-1);
    fetchCalendar();
  };

  useEffect(() => {
    initCheckedList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkedList?.length > 0) {
      fetchItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  // Get Redirection URL
  const getRedirectionUrl = (type) => {
    const urlMap = {
      Task: "/quick-projects/daily-reports/add",
      Milestone: "/quick-projects/daily-reports/add",
      "To Do": "/quick-talks/to-dos",
      Reminder: "/quick-talks/reminders",
      Meeting: "/quick-talks/meetings",
      Event: "/quick-talks/events",
      RFI: "/quick-projects/rfis",
      "Daily Report": "/quick-projects/daily-reports",
      "Created Invoice": "/quick-stocks/created-invoices",
      "Received Invoice": "/quick-stocks/received-invoices",
    };

    return urlMap[type] || "/";
  };

  useEffect(() => {
    if (!user?.is_admin) {
      const employee = employees?.filter((emp) => emp?.uid === user?.uid);

      if (employee) {
        setEmployeeId(employee?.[0]?.value);
        fetchCalendar();
      }
    }
  }, [user, employees]);

  useEffect(() => {
    if (!user?.is_admin) {
      fetchCalendar();
    }
  }, [employeeId]);

  console.log("calendarrrrr", data);

  return (
    <div>
      <Helmet>
        <title>Calendar</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        <Breadcrumb.Item>
          <Link to="/quick-projects/dashboard">Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to="/quick-projects/calendar">Calendar</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        title="Calendar"
        extra={[
          <Space>
            <Tooltip placement="top" title="Refresh">
              <Button icon={<ReloadOutlined />} onClick={fetchItems} />
            </Tooltip>

            <Tooltip placement="top" title="Settings">
              <Button icon={<SettingOutlined />} onClick={toggleSettings} />
            </Tooltip>
          </Space>,
        ]}
        style={styles.pageHeader}
      ></PageHeader>

      {user?.is_admin ? (
        <Space direction="vertical" style={{ width: "100%", marginBottom: 24 }}>
          <label>Employee</label>

          <Select
            style={{ minWidth: 180, width: "100%" }}
            placeholder="Select Employee"
            value={employeeId}
            onChange={setEmployeeId}
          >
            <Option value={-1}>All Employees</Option>

            {employees?.map((project) => (
              <Option value={project?.value}>{project?.label}</Option>
            ))}
          </Select>

          <Space>
            <Button onClick={onReset}>Reset</Button>
            <Button type="primary" onClick={fetchCalendar}>
              Search
            </Button>
          </Space>
        </Space>
      ) : (
        <p style={{ marginBottom: "20px", fontSize: "20px" }}>
          {user?.username}
        </p>
      )}

      {loading && (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      )}

      {!loading && (
        <div className="calendar">
          <div className="header">
            <Space>
              <Button
                icon={<CaretLeftOutlined />}
                onClick={handlePrev}
                shape="circle"
              />

              <div className="datetime">{dayObj.format("MMMM YYYY")}</div>

              <Button
                icon={<CaretRightOutlined />}
                onClick={handleNext}
                shape="circle"
              />
            </Space>
          </div>

          <div className="week-container">
            {weekDays.map((d) => (
              <div className="week-cell" key={d}>
                {d}
              </div>
            ))}
          </div>

          <div className="day-container">
            {range(weekDayOf1).map((i) => (
              <div className="day-cell day-cell--faded" key={i}>
                <h2 style={styles.dateNumber}>
                  {dayObjOf1.subtract(weekDayOf1 - i, "day").date()}
                </h2>
              </div>
            ))}

            {range(daysInMonth).map((i) => (
              <div
                className={`day-cell day-cell--in-month${i + 1 === todayObj.date() &&
                  thisMonth === todayObj.month() &&
                  thisYear === todayObj.year()
                  ? " day-cell--today"
                  : ""
                  }`}
                key={i}
              >
                <h2 style={styles.dateNumber}>{i + 1}</h2>

                {data?.map((item) => {
                  const calendarDate = moment({
                    year: dayObj.year(),
                    month: dayObj.month(),
                    day: i + 1,
                  }).format("YYYY-MM-DD");

                  if (
                    moment(item?.date)?.format("YYYY-MM-DD") === calendarDate
                  ) {
                    return (
                      <Link
                        to={`${getRedirectionUrl(item?.type)}`}
                        state={{ data: item }}
                      >
                        <div style={styles.task}>
                          <p style={styles.taskTitle}>{item?.name}</p>
                          <span style={styles.taskType}>{item?.type}</span>
                        </div>
                      </Link>
                    );
                  }
                })}
              </div>
            ))}

            {range(6 - weekDayOfLast).map((i) => (
              <div className="day-cell day-cell--faded" key={i}>
                <h2>{dayObjOfLast.add(i + 1, "day").date()}</h2>
              </div>
            ))}
          </div>
        </div>
      )}

      <Drawer
        title="Settings"
        placement="right"
        closable={true}
        onClose={toggleSettings}
        visible={showSettings}
        key="settingsDrawer"
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Select All
        </Checkbox>

        <div className="check-boxes-group">
          <CheckboxGroup
            options={OPTIONS?.map((item) => item?.label)}
            value={checkedList}
            onChange={onChange}
          />
        </div>
      </Drawer>
    </div>
  );
};

const styles = {
  toolbar: {
    marginBottom: 24,
  },
  rightToolbar: {
    textAlign: "right",
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  tag: {
    width: "100%",
    marginBottom: 6,
  },
  dateNumber: {
    marginBottom: 12,
    textAlign: "center",
    fontWeight: "normal",
  },
  taskTitle: {
    margin: 0,
    marginBottom: 0,
    marginTop: 6,
  },
  taskIcon: {
    width: 42,
    height: 42,
  },
  task: {
    background: "#EFE9FE",
    borderRadius: 12,
    padding: 12,
    marginBottom: 12,
    textAlign: "center",
  },
  taskType: {
    textTransform: "uppercase",
    fontSize: 10,
    fontWeight: "bold",
    color: "#888",
  },
  loaderContainer: {
    textAlign: "center",
    padding: 24,
  },
};

export default Calendar;
