import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Space,
  Button,
  Popconfirm,
  Tooltip,
  notification,
  Input,
  Modal,
  Select,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import ReactToPrint from "react-to-print";
import { ImFilePdf } from "react-icons/im";
import { BASE_API_URL, STRAPI_URL } from "../../../../../constants";

const { Option } = Select;

const Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [letterheadFileName, setLetterheadFileName] = useState("");
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);
  const [selectedData, setselectedData] = useState(null);
  const [terms, setTerms] = useState([]);
  const [companies, setCompanies] = useState([]);

  const componentRef = useRef();

  const styles = {
    toolbar: {
      marginBottom: 24,
    },
    rightToolbar: {
      textAlign: "right",
    },
    pageHeader: {
      padding: 0,
      paddingBottom: 24,
    },
    label: {
      display: "block",
      marginBottom: 3,
      marginTop: 6,
    },
    previewedImage: {
      width: "100%",
    },
    select: {
      width: "100%",
    },
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["id:asc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      const companyResponse = await axios.get(
        `${BASE_API_URL}/companies?populate=%2A&pagination%5Bpage%5D=1&pagination%5BpageSize%5D=9999`
      );

      const vendorResponse = await axios.get(
        `${BASE_API_URL}/vendor-terms?populate=%2A&pagination%5Bpage%5D=1&pagination%5BpageSize%5D=9999`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }

      if (companyResponse?.data) {
        setCompanies(companyResponse?.data?.data);
      }

      if (vendorResponse?.data) {
        setTerms(vendorResponse?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = (keyword) => {
    fetchData({
      $or: [
        {
          id: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          invoice_from: {
            $containsi:
              searchValues?.keyword?.toLowerCase() ?? keyword?.toLowerCase(),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          if (index === 0) {
            setLetterheadFileName(fileName);
          }

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) {}
  };

  function handlePrint() {
    setTimeout(() => {
      var element = componentRef?.current?.innerHTML;

      var opt = {
        margin: [0.03, 0, 0.3, 0],
        filename: "invoices_" + selectedData?.id + ".pdf",
        image: { type: "jpeg", quality: 0.5 },
        pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1" },
        html2canvas: {
          scale: 5,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        compressImage: false,
      };

      import("html2pdf.js").then((html2pdf) => {
        /* html2pdf().set(opt).from(element).save(); */
        html2pdf.default().set(opt).from(element).save();
      });
    }, 10);
  }

  useEffect(() => {
    fetchLetterheads();
  }, []);

  useEffect(() => {
    if (location?.state?.data?.name) {
      setSearchValues({ keyword: location.state.data.name });

      onSearch(location.state.data.name);
    } else {
      fetchData(filters);
    }
  }, [location?.state?.data]);

  return (
    <>
      <DataTable
        data={data}
        config={{
          ...config,
          columns: config?.columns
            ?.map((column) => ({
              ...column,
              ...getColumnProps(column),
            }))
            ?.concat({
              title: "Actions",
              width: 100,
              selectable: false,
              render: (render) => {
                return (
                  <Space>
                    <Tooltip
                      placement="bottom"
                      title={`Edit ${config?.entityNames?.singular}`}
                    >
                      <Button
                        icon={<EditOutlined />}
                        onClick={() =>
                          navigate(
                            `/quick-stocks/${config?.slugs?.plural}/edit/${render?.id}`
                          )
                        }
                      />
                    </Tooltip>

                    <Tooltip placement="bottom" title="Download PDF">
                      <Button
                        icon={<ImFilePdf style={{ margin: "0 auto" }} />}
                        onClick={() => {
                          setShowsModal(true);
                          setselectedData(render);
                        }}
                      />
                    </Tooltip>

                    <Popconfirm
                      title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteItem(render?.id)}
                    >
                      <Tooltip
                        placement="bottom"
                        title={`Remove ${config?.entityNames?.singular}`}
                      >
                        <Button type="danger" icon={<DeleteOutlined />} />
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                );
              },
            }),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Input
              value={searchValues?.keyword}
              placeholder="Search for keyword..."
              onChange={(e) =>
                setSearchValues((prev) => ({
                  ...prev,
                  keyword: e.target.value,
                }))
              }
            />

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
      />

      <Modal
        title="Print Invoice"
        visible={showsModal}
        onCancel={() => setShowsModal(false)}
        destroyOnClose
        width={800} // Setting the width of the modal
        footer={null} // Removing the footer of the modal
      >
        <label style={{ ...styles.label, marginTop: 0 }}>Letterhead</label>
        <Select
          value={letterheadFileName}
          onChange={setLetterheadFileName}
          style={styles.select}
        >
          {letterheads?.map((letterhead, index) => (
            <Option key={index} value={letterhead?.file_name}>
              {letterhead?.name}
            </Option>
          ))}
        </Select>

        <label style={styles.label}>Preview</label>
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
          style={styles.previewedImage}
          alt="Preview"
        />

        {/* Hidden Invoice component */}
        {/* <div style={{ position: 'absolute', left: '-9999px', top: 0 }}> */}
        <div
          ref={componentRef}
          style={{ position: "absolute", left: "-9999px", top: 0 }}
        >
          <Invoice
            data={selectedData}
            letterheadFileName={letterheadFileName}
            companies={companies}
            terms={terms}
          />
        </div>

        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Button type="primary" onClick={handlePrint}>
            Download PDF
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Page;

const Invoice = React.forwardRef(
  ({ data, letterheadFileName, companies, terms }, ref) => {
    const [companLogo, setCompanLogo] = useState(null);

    const totalSum =
      data && data?.invoices?.reduce((acc, item) => acc + item.total, 0);
    const taxableAmountSum =
      data &&
      data?.invoices?.reduce((acc, item) => acc + item.taxable_amount, 0);
    const totalTaxAmount =
      data && data?.invoices?.reduce((acc, item) => acc + item.tax_amount, 0);

    const totalSGSTAmount =
      data && data?.invoices?.reduce((acc, item) => acc + item?.sgst_amount, 0);

    const totalCGSTAmount =
      data && data?.invoices?.reduce((acc, item) => acc + item?.cgst_amount, 0);

    const totalIGSTAmount =
      data && data?.invoices?.reduce((acc, item) => acc + item?.igst_amount, 0);

    function numberToWords(num) {
      const a = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const b = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      const c = ["Hundred", "Thousand", "Lakh", "Crore"];

      if ((num = num.toString()).length > 9) return "overflow";
      let n = ("000000000" + num).substr(-9).match(/.{1,2}/g);
      if (!n) return "";

      let str = "";
      str +=
        n[0] !== "00"
          ? (a[Number(n[0])] || b[n[0][0]] + " " + a[n[0][1]]) +
            " " +
            c[3] +
            " "
          : "";
      str +=
        n[1] !== "00"
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) +
            " " +
            c[2] +
            " "
          : "";
      str +=
        n[2] !== "00"
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) +
            " " +
            c[1] +
            " "
          : "";
      str +=
        n[3] !== "00"
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) +
            " " +
            c[0] +
            " "
          : "";
      str +=
        n[4] !== "00"
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + " "
          : "";

      return str.trim();
    }

    function convertToCurrencyWords(num) {
      let [rupees, paise] = num.toFixed(2).split(".");

      let rupeesInWords = rupees > 0 ? numberToWords(rupees) + " Rupees" : "";
      let paiseInWords = paise > 0 ? numberToWords(paise) + " Paise" : "";

      console.log("rupeeee", rupeesInWords, "------", paiseInWords);

      return (
        rupeesInWords + (paiseInWords ? " and " + paiseInWords : "") + " Only"
      );
    }

    useEffect(() => {
      const logo = companies?.find(
        (comp) => comp?.id === data?.invoice_from?.data?.id
      );

      if (logo) {
        setCompanLogo(logo);
      }
    }, [companies, data]);

    console.log("selected dataaaaa", data);

    return (
      <div ref={ref} style={{ fontFamily: "Arial, sans-serif" }}>
        {/* <div style={{ width: '100%', marginBottom: '20px' }}>
        <img src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`} alt="" style={{ height: '100px', width: '100%', objectFit: 'cover' }} />
      </div> */}

        <div style={{ padding: "0 40px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "20px",
              gap: "5rem",
            }}
          >
            {/* <div>
          <img src={`${STRAPI_URL}${companLogo?.attributes?.logo?.data?.attributes?.url}`} alt="Authorised Signatory" style={{ width: '120px' }} />
        </div> */}
            <div style={{ flex: "1" }}>
              <div style={{ marginBottom: "15px" }}>
                <b>Invoice From</b>
                <p style={{ fontSize: "24px", fontWeight: "800" }}>
                  {data?.invoice_from?.data?.attributes?.name}
                </p>
              </div>
              <p>
                {data?.invoice_from?.data?.attributes?.address_line_1}, <br />
                {data?.invoice_from?.data?.attributes?.address_line_2}
              </p>

              <p>
                <b>GSTIN:</b> {data?.invoice_from?.data?.attributes?.gsin_no}
              </p>
              <p>
                <b>Email:</b> {data?.invoice_from?.data?.attributes?.email_1}
              </p>
              <div style={{ display: "flex", gap: "20px" }}>
                <p>
                  <b>Contact Person:</b>{" "}
                  {data?.invoice_from?.data?.attributes?.contact_person}
                </p>
                {/* <p><b>Contact No:</b> {data?.invoice_from?.data?.attributes?.gsin_no}</p> */}
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              {/* <img src="path_to_logo" alt="Mockingbird Logo" style={{ width: '150px' }} /> */}
              <h1 style={{ fontSize: "26px", fontWeight: "800" }}>
                {data?.invoice_type}
              </h1>
              <p style={{ fontSize: "24px", fontWeight: "800" }}>{data?.id}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              gap: "20px",
            }}
          >
            {data?.invoice_to?.length > 0 && (
              <div>
                <h2>
                  <b>Invoice To</b>
                </h2>
                <p>
                  <b>{data?.invoice_to?.[0]?.client_name}</b>
                </p>
                <p style={{ marginTop: "20px" }}>
                  {data?.invoice_to?.[0]?.po_address}
                </p>
                <p>
                  <b>GSTIN:</b> {data?.invoice_to?.[0]?.gstin_number}
                </p>
                <p>
                  <b>Place of Supply:</b>{" "}
                  {data?.invoice_to?.[0]?.place_of_supply}
                </p>
              </div>
            )}

            {data?.shipped_to?.length > 0 && (
              <div>
                <h2>
                  <b>Shipped To</b>
                </h2>
                <p>
                  <b>{data?.shipped_to?.[0]?.name}</b>
                </p>
                <p style={{ marginTop: "20px" }}>
                  {data.shipped_to?.[0]?.address}
                </p>
                <p>
                  <b>GSTIN:</b> {data?.shipped_to?.[0]?.gstin_number}
                </p>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "12px",
              fontWeight: "600",
              marginBottom: "20px",
              border: "1px solid #5555",
              borderRight: "none",
              borderLeft: "none",
              padding: "10px 0",
            }}
          >
            <h2>Invoice Number: {data?.id}</h2>
            <h2>Invoice Date: {data?.invoice_date}</h2>
          </div>

          <div
            style={{
              marginBottom: "20px",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)", // Adjust this to 'repeat(4, 1fr)' if you want 4 columns.
              gap: "1.5rem", // Adjust the gap between grid items
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            <p>
              PO Number: <br /> {data?.po_number}
            </p>
            <p>
              PO Date: <br /> {data?.po_date}
            </p>
            <p>
              OC Number: <br /> {data?.oc_number}
            </p>
            <p>
              OC Date: <br /> {data?.oc_date}
            </p>
          </div>

          <table
            style={{
              width: "100%",
              marginBottom: "20px",
              borderCollapse: "collapse",
              pageBreakInside: "avoid",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>#</th>
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                  Material Item
                </th>
                {/* <th style={{ border: '1px solid #ddd', padding: '10px' }}>Description</th> */}
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                  HSN/SAC Code
                </th>
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                  Quantity
                </th>
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                  Rate
                </th>
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                  Taxable Amount
                </th>
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                  Tax Amount
                </th>
                <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                  Total
                </th>
              </tr>
            </thead>
            {data?.invoices?.map((item, i) => (
              <tbody>
                <tr>
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    {i + 1}
                  </td>
                  {/* <td style={{ border: '1px solid #ddd', padding: '10px' }}>
                  <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                </td> */}
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    {item?.material_item}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    {item?.hsn_code}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    {item?.quantity}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    ₹{item?.rate}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    ₹{item?.taxable_amount}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    ₹{item?.tax_amount}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                    ₹{item?.total}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>

          <div
            style={{
              display: "flex",
              gap: "40px",
              justifyContent: "space-between",
              borderBottom: "2px solid #ddd",
              marginBottom: "10px",
            }}
          >
            <div style={{ flex: 2 }}>
              <strong>Quotation Amount:</strong>{" "}
              {convertToCurrencyWords(totalSum)}
            </div>

            <div style={{ textAlign: "right", flex: 1.2 }}>
              <strong>Total (before Tax):</strong> ₹{taxableAmountSum}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "40px",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div style={{ flex: 2 }}>
              <strong>Tax:</strong> {convertToCurrencyWords(totalTaxAmount)}
            </div>

            <div style={{ textAlign: "right", flex: 1.2 }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div style={{ textAlign: "right" }}>
                  <strong>CGST</strong>
                  <p>₹{totalCGSTAmount}</p>
                </div>
                <div style={{ textAlign: "right" }}>
                  <strong>SGST</strong>
                  <p>₹{totalSGSTAmount}</p>
                </div>
                <div style={{ textAlign: "right" }}>
                  <strong>IGST</strong>
                  <p>₹{totalIGSTAmount}</p>
                </div>
                <div style={{ textAlign: "right" }}>
                  <strong>Total Tax</strong>
                  <p>₹{totalTaxAmount}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "40px",
              justifyContent: "space-between",
              border: "1px solid #ddd",
              padding: "10px",
              pageBreakInside: "avoid",
              marginTop: "20px",
            }}
          >
            <div>
              <strong>Payable Amount:</strong> <br />{" "}
              {convertToCurrencyWords(totalSum)}
              <br />
            </div>

            <div style={{ fontSize: "24px" }}>
              <strong>Grand Total:</strong> &nbsp; &nbsp; ₹{totalSum}
            </div>
          </div>

          {data?.payment_schedule?.length > 0 && (
            <div style={{ marginTop: "20px", pageBreakInside: "avoid" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Payment Schedules
              </p>

              <table
                style={{
                  width: "100%",
                  marginTop: "10px",
                  borderCollapse: "collapse",
                  pageBreakInside: "avoid",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      #
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Description
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Amount (%)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Amount (₹)
                    </th>
                  </tr>
                </thead>
                {data?.payment_schedule?.map((item, i) => (
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {i + 1}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {item?.name}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {item?.amount_percentage}%
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        ₹{item?.amount}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          )}

          {data?.payment_term?.length > 0 && (
            <div style={{ marginTop: "20px", pageBreakInside: "avoid" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Vendor Terms & Conditions
              </p>
              <table
                style={{
                  width: "100%",
                  marginTop: "10px",
                  borderCollapse: "collapse",
                  pageBreakInside: "avoid",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      #
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Heading
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "10px" }}>
                      Description
                    </th>
                    {/* <th style={{ border: '1px solid #ddd', padding: '10px' }}>Material Group</th>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Material Item</th> */}
                  </tr>
                </thead>
                {data?.payment_term?.map((item, i) => (
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {i + 1}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {item?.heading}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </td>
                      {/* <td style={{ border: '1px solid #ddd', padding: '10px' }}>
                      {item?.material_group}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '10px' }}>{item?.material_item}</td> */}
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          )}

          {data?.bank?.data && (
            <div
              style={{
                margin: "10px 0",
                display: "flex",
                gap: "20px",
                pageBreakInside: "avoid",
              }}
            >
              <div style={{ flex: "1" }}>
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  Bank Details
                </h2>
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  <h2>
                    Bank Name: <br /> {data?.bank?.data?.attributes?.name}
                  </h2>
                  <p>
                    A/C Name: <br />{" "}
                    {data?.bank?.data?.attributes?.account_name}
                  </p>
                  <p>
                    A/C Number: <br />{" "}
                    {data?.bank?.data?.attributes?.account_number}
                  </p>
                  <p>
                    Branch: <br /> {data?.bank?.data?.attributes?.branch}
                  </p>
                  <p>
                    IFSC: <br /> {data?.bank?.data?.attributes?.ifc_number}
                  </p>
                  <p>
                    MICR: <br /> {data?.bank?.data?.attributes?.micr_number}
                  </p>
                  <p>
                    SWIFT Code: <br />{" "}
                    {data?.bank?.data?.attributes?.swift_code}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              borderTop: "1px solid #ddd",
              padding: "10px 0 50px",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              For {data?.invoice_from?.data?.attributes?.name}
            </p>

            <p style={{ fontSize: "12px" }}>Authorised Signatory</p>
          </div>
        </div>
      </div>
    );
  }
);
