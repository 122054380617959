import React, { useRef, useEffect, useState } from "react";
import { PageHeader, Breadcrumb, Button, Tooltip, Space, DatePicker, Col, Input, Row, Table } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import pluralize from "pluralize";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import qs from "qs";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { axios } from "../../../../../App";
import { ReportDataTable } from "../../../components";
import { Bar, Column, Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const ConstructionCostReport = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);
  const [chartData, setchartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([])


  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        // sort: ["date:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `projects?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );


        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };


  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              employee: {

                first_name: {
                  $containsi: searchValues?.keyword?.toLowerCase(),
                },

              }
            },
            {
              createdAt: {
                $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
              },
            },
          ],
        },

        getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    return buttons;
  };

  const getFiltersForRoles = () => {
    const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    if (user?.is_admin) {
      return {};
    }

    if (userType === "employee") {
      return {
        $or: [
          {
            project_managers: {
              uid: user?.uid,
            },
          },
          {
            members: {
              uid: user?.uid,
            },
          },
        ],
      };
    }

    return {
      [pluralize(userType)]: {
        uid: user?.uid,
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',

    },
    {
      title: 'Construction Cost',
      dataIndex: 'construction_cost',
      key: 'construction_cost',
      render: (text) => `${text ? text?.toLocaleString() : '0'}`, // Format the cost with a dollar sign and commas
    },
    {
      title: 'Construction Area (sq.ft)',
      dataIndex: 'construction_area',
      key: 'construction_area',
      render: (text) => text && `${text.toLocaleString()} sq.ft`, // Format the area with commas and add the unit
    },
    {
      title: 'Project Cost per sq.ft',
      dataIndex: 'project_cost',
      key: 'project_cost',
      render: (text) => `${text.toLocaleString()}`, // Format the cost per sq.ft with a dollar sign and commas
    },
  ];

  const construction_data =
    data?.map((con) => ({
      date: con.start_date,
      construction_cost: con.estimated_budget,
      construction_area: con.construction_area,
      project_cost: Math.round(con.estimated_budget / con.construction_area)
    }))

  // Calculate the total cost
  const totalCost = construction_data.reduce((sum, record) => sum + record.project_cost, 0);

  const totalCostData = {
    date: '',
    construction_cost: '',
    construction_area: '',
    project_cost: `Total Cost - ${totalCost}`
  }

  console.log('construction cost', data, [...construction_data, totalCostData])

  return (
    <Box>
      {construction_data && (
        <ReportDataTable
          data={[...construction_data, totalCostData]}
          config={{
            slugs: {
              plural: 'construction-cost-report'
            },
            pageTitle: 'Construction cost Report',
            columns: columns,

          }}
          loading={loading}
          pagination={pagination}

          Toolbar={
            <div className="toolbar">
              <Row gutter={16}>
                <Col span={10}>
                  <Input
                    value={searchValues?.keyword}
                    placeholder="Search for keyword..."
                    onChange={(e) =>
                      setSearchValues((prev) => ({
                        ...prev,
                        keyword: e.target.value,
                      }))
                    }
                  />
                </Col>

                {/* <Col span={7}>
                  <DatePicker
                    onChange={(value) =>
                      setSearchValues((prev) => ({
                        ...prev,
                        start_date: value,
                      }))
                    }
                    value={searchValues?.start_date}
                    placeholder="Select start date"
                    style={styles.datePicker}
                  />
                </Col>
    
                <Col span={7}>
                  <DatePicker
                    onChange={(value) =>
                      setSearchValues((prev) => ({
                        ...prev,
                        end_date: value,
                      }))
                    }
                    value={searchValues?.endDate}
                    placeholder="Select end date"
                    style={styles.datePicker}
                  />
                </Col> */}
              </Row>

              <div className="toolbar-buttons">
                <p className="toolbar-results">{getResultsMessage()}</p>

                <Space>
                  <Button onClick={onReset}>Reset</Button>
                  <Button type="primary" onClick={onSearch}>
                    Search
                  </Button>
                </Space>
              </div>
            </div>
          }
          buttons={renderHeaderButtons()}
          onBack={() => navigate(`/quick-projects/reports`)}
        />
      )}

      <Table
        columns={columns}
        dataSource={[...construction_data, totalCostData]}
        rowKey={(record) => record.date} // Use a unique key for each row, assuming date is unique
      />

    </Box>
  );
};

const styles = {
  breadcrumbs: {},
  pageHeader: {
    padding: 0,
    paddingBottom: 12,
  },
};

export default ConstructionCostReport;